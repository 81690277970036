import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { InView } from 'react-intersection-observer'
import tw, { css, theme } from 'twin.macro'
import ArticleGrid from '../../components/article-grid'
import Banner from '../../components/banner'
import FeaturedArticleCarousel from '../../components/featured-article-carousel'
import Layout from '../../components/layout'
import SubNav from '../../components/sub-nav'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'
import { getNewsUrl } from '../../utils/routing'
import debounce from 'lodash/debounce'

const NewsInsightsPage = ({ data: { newsInsights } }) => {
  const [activeLink, setActiveLink] = useState('#featured')
  const [scrollDirection, setScrollDirection] = useState('up')
  const [isMobile, setIsMobile] = useState(false)
  let lastScrollTop = 0; 
  const page = useMemo(
    () => flatten(newsInsights || {}, ['banner']),
    [newsInsights]
  )

  const handleScroll = debounce(() => {
    setScrollDirection(lastScrollTop > window.pageYOffset ? 'up' : 'down')
    lastScrollTop = window.pageYOffset
  }, 50)

  useEffect(() => {

    setIsMobile(
      parseFloat(document.documentElement.style.getPropertyValue('--vw').slice(0, -2)) < 10.24
    )

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', handleScroll, { passive: true })
    }
  }, [])

  const anchorLinks = useMemo(
    () => [
      {
        label: get(page, 'newsArticlesLabel'),
        link: '#news-articles',
        active: activeLink === '#news',
      },
      {
        label: get(page, 'insightsArticlesLabel'),
        link: '#insights-articles',
        active: activeLink === '#insights',
      },
    ],
    [activeLink, page]
  )

  const featured = useMemo(
    () =>
      (get(page, 'featuredArticles') || []).map((release) => ({
        date: release.date,
        arrowLabel: get(page, 'featuredArrowLabel'),
        headline: release.headline,
        excerpt: get(release, 'contentNode.childMarkdownRemark.excerpt'),
        type: release.tag,
        callToAction: {
          label: get(page, 'featuredButtonLabel'),
          link: `/news-insights/${release.typeOfArticle ? 'insights' : 'press-releases'}/${release.slug}`,
        },
        image:release.image,
        poster:release.poster,
        video:release.video,
        embedVideo:release.embedVideo, 
      })),
    [page]
  )

  const news = useMemo(
    () =>
      (get(page, 'newsArticles') || []).map((release) => ({
        date: release.date,
        headline: release.headline,
        excerpt: get(release, 'contentNode.childMarkdownRemark.excerpt'),
        callToAction: {
          label: get(page, 'newsArticlesButtonLabel'),
          link: `/news-insights/press-releases/${release.slug}`,
        },
      })),
    [page]
  )

  const insights = useMemo(
    () =>
    (get(page, 'insightsArticles' || []).map((article) => ({
          type: article.typeOfArticle,
          date: article.date,
          headline: article.headline,
          excerpt: get(article, 'contentNode.childMarkdownRemark.excerpt'),
          callToAction: {
            label: get(page, 'insightsArticlesButtonLabel'),
            link: `/news-insights/insights/${article.slug}`,
          },
          tag: article.tag,
          image: article.image,
          thumbnail: article.thumbnail,
        })))
        .sort((a, b) => new Date(b.date) - new Date(a.date)),
    [page]
  )

  return (
    <Layout
      pageTags={page.seoMetaTags}
      noIndex={page.noIndex}
    >
      <InView
        as="section"
        id="featured"
        rootMargin="-128px 0% -80% 0%"
        css={tw`col-span-4 md:col-span-8 lg:(col-start-3 col-end-13) xl:col-end-17 bg-secondary-500 bg-opacity-5 pt-14`}
        onChange={(view) => {
          if (view) setActiveLink('#featured')
        }}
      >
      <div css={global`layout.container`}>
        <FeaturedArticleCarousel
          articles={featured}
          noHero
      />
      </div>
      </InView>
      <SubNav
        links={anchorLinks}
        style={css`
          ${tw`sticky z-10 bg-white bg-opacity-100 top-15 lg:top-32`}
          li {
            ${tw`mx-8 first-of-type:ml-0`}
          }
          border-bottom: 1px solid #e5e5e5;
          ${isMobile && scrollDirection == 'up' && tw`top-30`}
        `}
        selectBackground={theme`colors.white`}
        currentPath={activeLink}
      />
      <InView
        as="section"
        id="news-articles"
        css={tw` md:py-20 lg:(pt-20 pb-0)`}
        rootMargin="-128px 0% -80% 0%"
      >
        <ArticleGrid
          articles={news}
          title={get(page, 'newsArticlesLabel')}
          numArticles={3}
          noSup
        />
      </InView>
      <InView
        as="section"
        id="insights-articles"
        css={tw`md:py-20 lg:(pt-20 pb-0)`}
        rootMargin="-128px 0% -80% 0%"
      >
        <ArticleGrid
          articles={insights}
          title={get(page, 'insightsArticlesLabel')}
          numArticles={6}
          showImages
          noSup
        />
      </InView>
      <section id="call-to-action" css={[global`layout.container`, tw`py-4 md:py-16`]}>
        <Banner
          title={get(page, 'banner.titleNode')}
          subtitle={get(page, 'banner.subtitleNode')}
          description={get(page, 'banner.descriptionNode')}
          callToAction={get(page, 'banner.callToAction')}
          buttonStyle={tw`mt-8 lg:mt-0`}
        />
      </section>
    </Layout>
  )
}

NewsInsightsPage.propTypes = {
  data: PropTypes.shape({
    newsInsights: PropTypes.object.isRequired,
  }),
}

export default NewsInsightsPage

export const query = graphql`
  query NewsInsightsQuery {
    newsInsights: datoCmsParentNewsInsight {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      featuredLabel
      featuredButtonLabel
      featuredArrowLabel
      featuredArticles {
        ... on DatoCmsInsightsArticle {
          typeOfArticle
          slug
          tag
          date
          headline
          subheading
          contentNode {
            childMarkdownRemark {
              excerpt
            }
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH)
            format
            url
          }
          video {
            video {
              thumbnailUrl(format: gif)
              streamingUrl
              mp4Url
            }
          }
          embedVideo {
            thumbnailUrl
            url
          }
        }
        ... on DatoCmsPressRelease {
          slug
          date
          headline
          contentNode {
            childMarkdownRemark {
              excerpt
            }
          }
          image {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      } 
      newsArticlesLabel
      newsArticlesButtonLabel
      newsArticles {
        slug
        date
        headline
        subheading
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      insightsArticlesLabel
      insightsArticlesButtonLabel
      insightsArticles {
        typeOfArticle
        slug
        tag
        date
        headline
        subheading
        contentNode {
          childMarkdownRemark {
            excerpt
          }
        }
        thumbnail {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      banner {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        subtitleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
      backLabel
    }
  }
`

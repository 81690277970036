import PropTypes from 'prop-types'
import React, { useState, useMemo } from 'react'
import tw, { css } from 'twin.macro'
import { global } from '../styles/global'
import { format } from '../utils/date'
import Button from './button'
import Heading from './heading'
import Image from './image'
import Link from './link'
import Text from './text'

const ArticleGrid = ({ articles, title, placeholder, showArticles, showImages, noSup, numArticles }) => {
  const [displayCount, setDisplayCount] = useState(numArticles)

  const displayedArticles = useMemo(() => {
    return articles?.slice(0, displayCount)
  }, [articles, displayCount])

  return (
    <div css={[global`layout.container`, tw`flex flex-col`]}>
      <div css={tw`flex flex-row`}>
        <Heading headingType="h2" content={title} style={[tw`text-primary-500 text-opacity-40`]} />
        {!noSup && (
          <sup css={tw`ml-4 top-1/3`}>
            <h4 css={tw`font-normal text-primary-500 text-opacity-40`}>{articles.length || 0}</h4>
          </sup>
        )}
      </div>
      {showArticles ? (
        <>
          {displayedArticles && (
            <div css={tw`grid grid-cols-1 gap-y-14 mt-12 lg:(grid-cols-3 gap-x-12 gap-y-24)`}>
              {displayedArticles
                .sort((a, b) => new Date(b.date) - new Date(a.date))
                .map((article) => (
                  <Link key={article.headline} to={article.callToAction.link}>
                    <article
                      css={css`
                        ${tw`flex flex-col items-start`}
                        &:hover {
                          button > svg {
                            ${tw`lg:(transform translate-x-2)`}
                          }
                        }
                      `}
                    >
                      {showImages && (
                        <div
                          css={css`
                            ${tw`relative w-full mb-6`}
                            padding-top: 100%;
                          `}
                        >
                          {article.thumbnail ? (
                            <Image image={article.thumbnail} style={tw`absolute inset-0`} />
                          ) : (
                            <Image image={article.image} style={tw`absolute inset-0`} />
                          )}
                        </div>
                      )}
                      <div
                        css={[
                          global`typography.subtitle`,
                          tw`mb-5 font-bold text-primary-500 text-opacity-47`,
                        ]}
                      >
                        {article.tag ? `${article.tag} - ` : undefined}
                        <time>{format(article.date, 'dd MMMM yyyy')}</time>
                      </div>
                      <Heading
                        headingType="h4"
                        content={article.headline}
                        style={tw`font-bold text-primary-500`}
                      />
                      <Text content={article.excerpt} style={tw`mt-6`} />
                      {article.callToAction && (
                        <Button
                          style={css`
                            ${tw`mt-12 bg-transparent text-primary-500`}
                            span {
                              ${tw`ml-0`}
                            }
                            svg {
                              ${tw`text-primary-500 lg:(transition-transform duration-300 ease-in-out)`}
                            }
                          `}
                          type="primary"
                          size="sm"
                          label={article.callToAction.label}
                        />
                      )}
                    </article>
                  </Link>
                ))}
            </div>
          )}
        </>
      ) : (
        <p style={tw`mt-6 ml-2`}>{placeholder}</p>
      )}
      <div
        css={[tw`flex justify-center pt-16 pb-4`, displayCount >= articles?.length && tw`hidden`]}
      >
        <Button
          label="Load more"
          type="primary"
          size="sm"
          theme="transparent-transparent"
          style={tw`border border-primary-500`}
          onClick={() => setDisplayCount(displayCount + 9)}
        />
      </div>
    </div>
  )
}

ArticleGrid.defaultProps = {
  showArticles: true,
  showImages: false,
  numArticles: 6,
}

ArticleGrid.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  placeholder: PropTypes.string,
  showArticles: PropTypes.bool,
  showImages: PropTypes.bool,
  numArticles: PropTypes.number,
}

export default ArticleGrid
